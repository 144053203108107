import { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../footer/footer';

interface SupportProps { }

interface IFormInput {
  firstName: string
  lastName: string
  phone: number
  email: string
  address: string
  zip: string
  notes: string,
  termsAgree: boolean
}

const Support: FC<SupportProps> = () => {
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<IFormInput>()

  const handleInputChange = (e: any) => {
    const value = e.target.value.slice(0, 10);
    setValue('phone', value);
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (data.phone && (data.phone + '').length < 10) {
      toast.error('Please enter valid phone number');
      return;
    }
    // setText('Thank you for your submission. Our team will get back to you shortly.');
    // alert('Your request is submitted , Our team will get back to you');
    toast.success('Thank you for your submission. Our team will get back to you shortly.',
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
        duration: 10000
      });
    reset();
  }

  return (
    <>
      <div className='-container' style={{ borderTop: 'solid 1px #b4e6ff' }}>
        <div className='d-flex flex-wrap'>
          <div className='img--wrapper'>
            <img src='assets/images/support-left.png' />
          </div>
          <div className='col d-flex right--wrapper' style={{ flexDirection: 'column' }}>
            <div className='inner--wrapper'>

              <div className='container__narrow'>
                <div className="support-header mb-4">
                  <p style={{ color: '#667085', fontSize: '18px' }} className="text-5">Reach out to us</p>
                  <p style={{ fontWeight: 700, fontSize: '32px', color: 'black' }} className="">We'd love to hear from you!</p>
                </div>
                <form className='support-form form' onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col form-group'>
                      <label className='floating-label'>First Name</label>
                      <input className='form-control floating-input'  {...register("firstName", { required: true, maxLength: 20 })} />
                      {errors.firstName && <p className='danger'>{'Please enter valid first name'}</p>}
                    </div>
                    <div className='col form-group'>
                      <label className='floating-label'>Last Name</label>
                      <input className='form-control floating-input'  {...register("lastName", { required: true, maxLength: 20 })} />
                      {errors.lastName && <p className='danger'>{'Please enter valid last name'}</p>}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col form-group'>
                      <label className='floating-label'>Phone Number</label>
                      <input className='form-control floating-input' type="number" min={1} {...register("phone", { required: true })} onChange={handleInputChange} />
                      {errors.phone && <p className='danger'>{'Please enter valid phone number'}</p>}
                    </div>
                    <div className='col form-group'>
                      <label className='floating-label'>Your Email</label>
                      <input className='form-control floating-input' {...register("email", {
                        required: true, maxLength: 100,
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org)$/,
                          message: 'Invalid email format, must include @ and end with com, in or org'
                        }
                      })
                      } />
                      {errors.email && <p className='danger'>{errors.email.message}</p>}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col form-group'>
                      <label className='floating-label'>Address</label>
                      <input className='form-control floating-input' type="text" {...register("address", { required: true })} />
                      {errors.address && <p className='danger'>{'Please enter valid address'}</p>}
                    </div>
                    <div className='col form-group'>
                      <label className='floating-label'>Zip code</label>
                      <input type='number' className='form-control floating-input'  {...register("zip", { required: true })} />
                      {errors.zip && <p className='danger'>{'Please enter valid zip'}</p>}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='form-group'>
                      <label className='floating-label'>What's on your mind?</label>
                      <textarea style={{ resize: 'none' }} className='form-control floating-input' rows={2} {...register("notes", { required: true })} />
                      {errors.notes && <p className='danger'>{'Please enter valid notes'}</p>}
                    </div>
                  </div>

                  <div className='d-flex justify-content-center'>
                    <input className='mt-2' id='messagesAgree' type='checkbox' style={{ width: '20px', height: '20px', marginRight: '15px' }} />
                    <label className='col' htmlFor="messagesAgree">By checking this box, you consent to receive text messages from the Finance Mutual App. Message frequency may vary. Standard message and data rates may apply. Reply HELP for assistance or STOP to opt-out.</label>
                  </div>

                  <div className='d-flex justify-content-center'>
                    <input {...register("termsAgree", { required: true })} className='mt-2' id='termsAgree' type='checkbox' style={{ width: '20px', height: '20px', marginRight: '15px' }} />
                    <label className='col' htmlFor="termsAgree">
                    By checking this box, you agree to the
                      <a target="_blank" className="link" href="/privacy-policy">Privacy Policy</a> and
                      <a target="_blank" className="link" href="/terms-conditions">Terms and Conditions</a> of the Finance Mutual App.
                    </label>
                  </div>
                  <div>
                    {errors.termsAgree && <p className='danger'>{'Please accept privacy policy and Terms and Conditions  '}</p>}
                  </div>

                  <div className=' d-flex mt-3'>
                    <button className='btn btn-primary w-auto' type='submit'>Send Message</button>
                  </div>
                  {/* {!text && <div className=' d-flex'>
                  <button className='btn btn-primary w-auto' type='submit'>Send Message</button>
                </div>}
                <div style={{color: '#1a3365', fontWeight: 700, fontSize: '18px'}}> {text} </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Toaster position="top-right" />
    </>
  )
}

export default Support;
