import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routing from './Routing';
import Header from './pages/header/header';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Header/>
    {/* <App /> */}
    <section className='support-section'>
      <Routing />
    </section>
  </React.StrictMode>
);
