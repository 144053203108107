import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';


interface footerProps {}

interface IFormInput {
  email: string
}

const Footer: FC<footerProps> = () => {

  const { register, handleSubmit, reset , formState: { errors }} = useForm<IFormInput>()
  const onSubmit: SubmitHandler<IFormInput> = () => {
    toast.success('Thank you for subscribing to our newsletter. We appreciate your interest and look forward to keeping you updated with our latest news and updates.',
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
        duration: 10000
      });
    reset();
  }
  return (
    <>
    <section className="footer" style={{background: '#1A3365'}}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div style={{color: 'white', fontSize: '24px', fontWeight: '700' }}>
              Connect Effortlessly. Grow Rapidly.
            </div>
            <div className="pt-3 pb-4" style={{color: '#12A9F6', fontSize: '18px', fontWeight: '500'}}>
            support@financemutualapp.com
            </div>
            <div >
              <a target="_blank" href="https://www.facebook.com/financemutual/">
                <img src="/assets/images/fb.svg" />
              </a>
              <a target="_blank" href="https://www.instagram.com/finance_mutual_/">
                <img className="px-2" src="/assets/images/insta.svg" />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/thefinancemutual/">
                <img className="px-2" src="/assets/images/linkedIn.svg" />
              </a>
              <a target="_blank" href="https://twitter.com/FinanceMutual_">
                <img className="px-2" src="/assets/images/twitter.svg" />
              </a>
            </div>
          </div>
          <div className="col" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '0px 120px', alignItems: 'flex-end'}}>
            <div className="position-relative">
              <div className="py-1" style={{color: 'white', fontSize: '20px', fontWeight: '600' }}>Join our newsletter</div>
              <form className='support-form form' onSubmit={handleSubmit(onSubmit)}>
                  <div className='col form-group'>
                    {/* <label className='floating-label'>Your Email</label> */}
                    <input style={{minWidth: '350px', maxWidth: '350px'}} className="floating-input p-2" placeholder="Email address" {...register("email", {
                      required: true, maxLength: 100,
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org)$/,
                        message: 'Invalid email format, must include @ and end with com, in or org'
                      }
                    })
                    } />
                    <img onClick={() => handleSubmit(onSubmit)()} style={{position: 'absolute', right: '0', top: '50px', width: '30px', cursor: 'pointer'}} src="/assets/images/arrow-left.png" />
                    {errors.email && <p className='danger'>{errors.email.message}</p>}
                  </div>
              </form>
              {/* <input style={{minWidth: '350px', maxWidth: '350px'}} className="floating-input p-2" placeholder="Email address" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
};

export default Footer;
