import { FC } from 'react';
import Footer from '../footer/footer';

interface PrivacyPolicyProps {}

const PrivacyPolicy: FC<PrivacyPolicyProps> = () => {
  return (
    <>
      <div className="container">
            <div className="row">
                <div className="col-12 text-center d-flex justify-content-between pt-5">
                    <h1 className="heading-1"><b>FinanceMutualApp Privacy Policy</b></h1>
                    <p style={{color: '#667085', fontWeight: 700}}>Effective Date: August 15, 2024</p>
                </div>
            </div>
        </div>

        <section className="privacy-content">
        <div className="container">
            <div className="row">
                <div className="col-12 content-inner">
                    <h3 className="sub-heading" style={{color: '#667085'}}>Privacy Policy</h3>
                    <p className="sub-heading">1. Our approach to privacy</p>
                    <p>1.1 FinanceMutualApp ("FinanceMutualApp") is committed to protecting your privacy. This privacy policy sets out how we collect, store, process, transfer, share and use data that identifies or is associated with you ("personal information") and information regarding our use of cookies and similar technologies.</p>
                    <p>1.2 FinanceMutualApp operates a cloud-based legal practice management solution available via our websites (our "Websites") including at Financemutualapp.com and mobile application as well as other products and services that we make available (the "FinanceMutualApp Service").</p>
                    <p>1.3 This privacy policy applies to the FinanceMutualApp Service.</p>
                    <p>1.4 Before accessing or using the FinanceMutualApp Service, please ensure that you have read and understood our collection, storage, use and disclosure of your personal information as described in this privacy policy. By accessing or using the FinanceMutualApp Service, you are accepting and consenting to the practices described in this privacy policy.</p>
                    <p className="sub-heading">2. Personal information we collect about you and how we use it</p>
                    <p>2.1 Information you give to us. We collect personal information about you when you voluntarily submit information directly to us by filling in forms on our Website or by corresponding with us by phone, email or other means. This includes information you provide when you register to use our Website, subscribe to the FinanceMutualApp Service, participate in any discussion boards, forums or other social media functions on our site or enter a competition, promotion or survey and when you report a problem with our Website, or use some other feature of the FinanceMutualApp Service as available from time to time.</p>
                    <p>2.2 If you choose not to provide personal information, we may not be able to provide the FinanceMutualApp Service to you or respond to your other requests.</p>
                    <p>2.3 Information we receive from other sources. We may receive personal information about you from individuals or corporate entities which are subscribers to the FinanceMutualApp Service ("Subscribers") where you are to be designated a user of the FinanceMutualApp Service. We may receive personal information about you if you use any of the other websites we operate or the other services we provide from time to time. We also work closely with third parties (including, for example, subcontractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them, subject to your agreements with them.</p>
                    <p>2.4 The table at Annex 1 sets out the categories of personal information you provide to us and that we receive from other sources and how we use that information. The table also lists the legal basis which we rely on to process the personal information and information as to how we determine applicable retention periods.</p>
                    <p>2.5 We also automatically collect personal information about you indirectly about how you access and use the FinanceMutualApp Service and information about the device you use to access the FinanceMutualApp Service.</p>
                    <p>2.6 The table at Annex 2 sets out the categories of personal information we collect about you automatically and how we use that information. The table also lists the legal basis which we rely on to process the personal information and information as to how we determine applicable retention periods.</p>
                    <p>2.7 We may link or combine the personal information we collect and/or receive about you and the information we collect automatically. This allows us to provide you with a personalized experience regardless of how you interact with us.</p>
                    <p>2.8 We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the FinanceMutualApp Service and developing new products and features. We may also share such anonymized information with others.</p>
                    <p className="sub-heading">3. Disclosure of your personal information</p>
                    <p>3.1 We may share your personal information with any member of our group, which includes our subsidiaries. We will not share your personal information with any third parties except as described in this privacy policy or in connection with the Service. We may share your information with selected third parties, including:</p>
                    <ul>
                        <li>Business partners, vendors, suppliers, and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us);</li>
                        <li>Analytics and search engine providers that assist us in the improvement and optimization of our Website;</li>
                        <li>Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;</li>
                        <li>Payment processors for the purpose of fulfilling relevant payment transactions;</li>
                    </ul>
                    <p>3.2 In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements.</p>
                    <p>We may disclose personal information in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions of service (<a href="https://financemutualapp.com/terms-conditions/">https://financemutualapp.com/terms-conditions/</a> ) and other agreements; or to protect the rights, property, or safety of FinanceMutualApp, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
                    <p>3.3 Publicly accessible blogs. Our Website includes publicly accessible blogs or community forums. Any information you provide in these areas may be read, collected and used by others who access them. This includes information posted on our public social media accounts. To request removal of your personal information from our blog or community forum, contact us at <a href="mailto:support@financemutualapp.com">support@financemutualapp.com</a>. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</p>
                    <p>3.4 Testimonials. With consent, we may display personal testimonials of satisfied customers on our site, along with other endorsements. If you wish to update or delete your testimonial, you can contact us at <a href="support@financemutualapp.com">support@financemutualapp.com</a>.</p>
                    <p>3.5 We may disclose personal information to third parties in connection with a business transaction. Personal information may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business. If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership that impacts the use of your personal information, as well as any choices you may have regarding your personal information.</p>
                    <p className="sub-heading">4. Marketing and advertising</p>
                    <p>4.1 From time to time we may contact you with relevant information about the FinanceMutualApp Service and our other products and services. Most messages will be sent electronically. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you.</p>
                    <p>4.2 If you do not want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. You can also change your marketing preferences at any time by accessing the subscription center at landing.Financemutualapp.com/subscribe.html or by following the unsubscribe link at the bottom of our emails.</p>
                    <p className="sub-heading">5. Storing and transferring your personal information</p>
                    <p>5.1 Security. FinanceMutualApp has implemented administrative, technical, and physical safeguards to protect its and its customers' information. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone.</p>
                    <p>5.2 While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</p>
                    <p className="sub-heading">6. Retaining your information</p>
                    <p>6.1 We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.</p>
                    <p>6.2 To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.</p>
                    <p className="sub-heading">7. Your rights in respect of your personal information</p>
                    <p>7.1 In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:</p>
                    <ul>
                        <li>Right of access and portability. The right to obtain access to your personal information along with certain information, and to receive that personal information in a commonly used format and to have it ported to another data controller.</li>
                        <li>Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.</li>
                        <li>Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed.</li>
                        <li>Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you or the sale of your personal information for a period enabling us to verify the accuracy of that personal information.</li>
                        <li>Right to object. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to the processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing.</li>
                        <li>Right to non-discrimination. The right to non-discrimination for exercising your rights as outlined in this policy. This includes, but is not limited to, denying you goods or services, charging you different prices for similar services, or providing a different level or quality of service.</li>
                    </ul>
                    <p>7.2 If you wish to exercise one of these rights, please contact us using the contact details at the end of this privacy policy. Upon request, we will provide you with information about whether we hold any of your personal information. We may request that you verify your identity prior to transferring personal information. You may access, correct or request deletion of your personal information by logging into your FinanceMutualApp Service account. We will respond to your request within 30 days.</p>
                    <p>7.3 FinanceMutualApp does not sell personal information shared by you. FinanceMutualApp has not sold personal information shared by you in the 12 months preceding the modification date for this policy. All use of personal information is done for the delivery, use, and improvement of the Service, as listed in 3.1.</p>
                    <p className="sub-heading">8. Cookies and similar technologies</p>
                    <p>8.1 Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you from other users of our Service. This helps us to provide you with good service. This helps us enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes. As is true of most websites, we gather certain information automatically. This information we may collect is described in detail in Annex 2.</p>
                    <p>8.2 We use the following types of cookies:</p>
                    <ul>
                        <li>Strictly necessary cookies. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use.</li>
                        <li>Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily.</li>
                        <li>Functionality cookies. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                        <li>Targeting cookies. These cookies record your visit to our Service, the pages you have visited and the links you have followed. We will use this information to make our Service and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
                    </ul>
                    <p>8.3 Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
                    <p>8.4 You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site.</p>
                    <p>8.5 Social Media. Our Website includes social media features, such as Facebook Like button and widgets such as the Share button. These features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the feature to function properly. Social media features and widgets are hosted on our site or by a third party, and your interactions with these features are governed by the privacy policy of the company providing it.</p>
                    <p className="sub-heading">9. Links to third party sites</p>
                    <p>9.1 The FinanceMutualApp Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites.</p>
                    <p>9.2 Some of the pages on our Website may utilize framing techniques to serve content to/from our partners while preserving the look and feel of our Website. Please be aware that you are providing your personal information to these third parties and not to FinanceMutualApp.</p>
                    <p className="sub-heading">10. Our policy towards children</p>
                    <p>10.1 The FinanceMutualApp Service is not directed at persons under 18 and we do not intend to collect personal information from children under 18 in our capacity as a controller. If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy.</p>
                    <p className="sub-heading">11. Changes to this policy</p>
                    <p>11.1 We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the "last modified" date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.</p>
                    <p className="sub-heading">12. Notice to you</p>
                    <p>12.1 If we need to provide you with information about something, whether for legal, marketing or other business related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.</p>
                    <p className="sub-heading">13. Contacting us</p>
                    <p>13.1 Any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to our Legal Department at <a href="mailto:support@financemutualapp.com">support@financemutualapp.com</a>.</p>
                    <p className="sub-heading">14. California Online Privacy Protection Act</p>
                    <p>14.1 CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.</p>
                    <p>14.2 According to CalOPPA, we agree to the following:</p>
                    <ul>
                        <li>Users can visit our site anonymously.</li>
                        <li>Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.</li>
                        <li>Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.</li>
                        <li>You will be notified of any Privacy Policy changes on our Privacy Policy Page</li>
                        <li>Can change your personal information by logging in to your account</li>
                    </ul>
                    <p className="sub-heading">Annex 1 - Personal information we collect</p>
                    <p>All disclosures listed below are made with service providers only and done in accordance with FinanceMutualApp's Terms of Service and Privacy Policy respectively.</p>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Category of personal information</th>
                                    <th scope="col">How we use it</th>
                                    <th scope="col">Legal basis for the processing</th>
                                    <th scope="col">CCPA Categorization</th>
                                    <th scope="col">Previous 12-month disclosures</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Contact information and basic personal details.</strong> Such as your name, phone number, address, location, IP address, e-mail address and where applicable, professional details such as your bar membership number.</p>
                                    </td>
                                    <td>
                                        <p>We use this information to communicate with you, including sending statements, news, alerts and marketing communications</p>
                                        <p>We use this information to deal with inquiries and other requests made by or about you, including customer service issues, relating to the FinanceMutualApp Service.Such communications may include direct mailing.</p>
                                        <p>We use this information to operate, maintain and provide to you the features and functionality of the FinanceMutualApp Service.</p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for our legitimate interests, namely for marketing purposes, and for communicating with you effectively and responding to your queries.</p>
                                        <p>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).The processing is necessary for the fulfillment of legal requirements, including the verification of the identity of customers.</p>
                                    </td>
                                    <td>
                                        <p>Identifiers</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Email account username and password.</p>
                                    </td>
                                    <td>
                                        <p>Where you have chosen to import contacts from your Outlook or other email account address book to invite them to become members of our Website, we collect the username and password for the email account you wish to import your contacts from.</p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).</p>
                                    </td>
                                    <td>
                                        <p>Identifiers</p>
                                    </td>
                                    <td>
                                        <p>Yes for email account username</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Correspondence and comments. When you contact us directly, e.g. by email, phone, mail, or when you interact with customer service, we will record your comments and opinions.</p>
                                    </td>
                                    <td>
                                        <p>To address your questions, issues, and concerns and resolve your customer service issues.</p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for our legitimate interests, namely communicating with you effectively for the purposes of resolving your issues.</p>
                                    </td>
                                    <td>
                                        <p>Audio, electronic, visual, thermal, olfactory, or similar information</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Payment information.Details such as your credit card or other financial information including credit scores obtained from credit reference agencies.</p>
                                    </td>
                                    <td>
                                        <p>We use this information to facilitate payment through or for use of the FinanceMutualApp Service, to assess your credit score and to detect and prevent fraud.</p>
                                    </td>
                                    <td>
                                        <p>The processing for assessing your credit score and facilitating payment is necessary for the performance of our contract (namely our Terms of Service).The processing is necessary for our legitimate interests, namely the detection and prevention of fraud.</p>
                                    </td>
                                    <td>
                                        <p>Personal Information (as defined by California Customer Records Statute)</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Recruiting details. Contact information and basic personal details (as set out above); professional details and employment information such as resume, references, LinkedIn profile.</p>
                                    </td>
                                    <td>
                                        <p>We use this information to facilitate recruiting.</p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for our legitimate interests, namely assessing your suitability for a role with FinanceMutualApp.</p>
                                    </td>
                                    <td>
                                        <p>Employment / Education Information</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>All personal information set out above.</p>
                                    </td>
                                    <td>
                                        <p>We will use all the personal information we collect to operate, maintain and provide to you the features and functionality of the FinanceMutualApp Service, to monitor and improve the FinanceMutualApp Service, our Website and business, for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes, to keep the Website safe and secure and to help us develop new products and services.</p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for our legitimate interest, namely to administer and improve the FinanceMutualApp Service, our business and develop new services.</p>
                                    </td>
                                    <td>
                                        <p>Commercial information</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="sub-heading">Annex 2 - Personal information collected automatically</p>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Category of personal information</th>
                                    <th scope="col">How we use it</th>
                                    <th scope="col">Legal basis for the processing</th>
                                    <th scope="col">CCPA Categorization</th>
                                    <th scope="col">Previous 12-month disclosures</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Information about how you access and use the FinanceMutualApp Service. For example, the website from which you came and the website to which you are going when you leave our Website, your social media profiles, how frequently you access the FinanceMutualApp Service, the time you access the FinanceMutualApp Service and how long you use it for, whether you open emails or click the links contained in emails, whether you access the FinanceMutualApp Service from multiple devices, and other actions you take on the FinanceMutualApp Service. We also gather information, which may include Internet protocol (IP) addresses,\ browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. </p>
                                    </td>
                                    <td>
                                        <p>We use this information to:
                                        </p><ul>
                                            <li>conduct market analysis, monitor the FinanceMutualApp Service and how it is used in order to and improve our business and help us develop new products and services;</li>
                                            <li>Generate marketing leads and determine news, alerts and other products and services that may be of interest to you for marketing purposes.</li>
                                        </ul>
                                        <p></p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for our legitimate interests, namely: to conduct relevant analysis to improve the FinanceMutualApp Service generally and for marketing purposes.</p>
                                    </td>
                                    <td>
                                        <p>Geolocation information, Inferences about personal preferences and attributes drawn from profiling, Internet activity</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Information about your device. We also collect information about the computer, tablet, smartphone or other electronic devices you use to connect to the FinanceMutualApp Service. This information can include details about the type of device, unique device identifying numbers, operating systems, browsers, and applications connected to the FinanceMutualApp Service through the device, your Internet service provider or mobile network, your IP address.</p>
                                    </td>
                                    <td>
                                        <p>We use this information to:
                                        </p><ul>
                                            <li>enable the FinanceMutualApp Service to be presented to you on your device; and</li>
                                            <li>operate, maintain and provide to you the features and functionality of the FinanceMutualApp Service.</li>
                                        </ul>
                                        <p></p>
                                        <p>We use this information to monitor and improve the FinanceMutualApp Service and business and to help us develop new products and services.</p>
                                    </td>
                                    <td>
                                        <p>The processing is necessary for the performance of a contract and (namely our Terms of Service).The processing is necessary for our legitimate interests, namely: to tailor the FinanceMutualApp Service to the user and to improve the FinanceMutualApp Service generally.</p>
                                    </td>
                                    <td>
                                        <p>Internet or other electronic network activity information</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
};

export default PrivacyPolicy;
