import React, { FC } from 'react';
import './header.scss';
interface headerProps {}

const Header: FC<headerProps> = () => {
  return (
    <>
      <header className="header-main p-3 py-3 position-sticky top-0 w-100" id="header">
        <div className="container d-flex justify-content-between align-items-center">
            <a className='text-white m-0 fs-20 fw-800' href='/'>FinanceMutual<span className='text-lightblue'>App</span></a>
            <ul className="d-flex align-items-center header-menus">
            <li className="me-3"><a className="fs-14 fw-700 " href="/terms-conditions">Terms</a></li>
            <li className="me-3"><a className="fs-14 fw-700 " href="/privacy-policy">Privacy Policy</a></li>
            <li className="me-3"><a className="fs-14 fw-700 " href="/support">Support</a></li>
            </ul>
        </div>
     </header>
    </>
  )
}

export default Header;
